<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >
        

        <el-form-item label="企业名称" prop="compName">
         <el-input :modelValue="form.compName" readonly></el-input>
        </el-form-item>

        <el-form-item label="总报修次数" prop="totalRepair">
         <el-input :modelValue="form.totalRepair" readonly></el-input>
        </el-form-item>

        <el-form-item label="保守次数" prop="conservative">
         <el-input :modelValue="form.conservative" readonly></el-input>
        </el-form-item>

        <el-form-item label="定检次数" prop="periodicInspection">
         <el-input :modelValue="form.periodicInspection" readonly></el-input>
        </el-form-item>

        <el-form-item label="有偿次数" prop="paid">
         <el-input :modelValue="form.paid" readonly></el-input>
        </el-form-item>

        <el-form-item label="纳品次数" prop="napin">
         <el-input :modelValue="form.napin" readonly></el-input>
        </el-form-item>

        <el-form-item label="服务器报修" prop="serverRepair">
         <el-input :modelValue="form.serverRepair" readonly></el-input>
        </el-form-item>

        <el-form-item label="网络报修" prop="networkRepair">
         <el-input :modelValue="form.networkRepair" readonly></el-input>
        </el-form-item>

        <el-form-item label="PC报修" prop="pcRepair">
         <el-input :modelValue="form.pcRepair" readonly></el-input>
        </el-form-item>

        <el-form-item label="弱电报修" prop="weakCurrent">
         <el-input :modelValue="form.weakCurrent" readonly></el-input>
        </el-form-item>

        <el-form-item label="其他报修" prop="otherRepair">
         <el-input :modelValue="form.otherRepair" readonly></el-input>
        </el-form-item>

        <el-form-item label="总移动时间" prop="totalMovingTime">
         <el-input :modelValue="form.totalMovingTime" readonly></el-input>
        </el-form-item>

        <el-form-item label="总对应时间" prop="totalCorrespondingTime">
         <el-input :modelValue="form.totalCorrespondingTime" readonly></el-input>
        </el-form-item>

        <el-form-item label="报修类型" prop="repairType">
         <el-input :modelValue="form.repairType" readonly></el-input>
        </el-form-item>

        <el-form-item label="报修项目" prop="repairProject">
         <el-input :modelValue="form.repairProject" readonly></el-input>
        </el-form-item>

        <el-form-item label="对应时间" prop="correspondingTime">
         <el-input :modelValue="form.correspondingTime" readonly></el-input>
        </el-form-item>

        <el-form-item label="日期" prop="reportDate">
         <el-input :modelValue="form.reportDate" readonly></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'


export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
        
      },
      // 验证选项
      rules: {},
    };
  },
  components: {
    
  },
  computed: {
    // 由于子组件不能修改继承来的对象，所以重新复制一份
    form: function () {
      var formData = this.params.data
      
      return formData
    }
  },
  created() {
    
  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    
    // 返回事件
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>